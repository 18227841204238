import { ChatQuestion } from "@/ts/interfaces/Question";

type modAction = { action: 'modify', id: number, updates: { [key: string]: any } }

const remove80 = { action: 'remove', id: 80 }
const allModifications = {
    recovered: [
        {
            action: 'modify',
            id: 136,
            updates: {
                text: "האם [g:נעזרת,נעזרת,נעזרתם], בעת ש[g:חלית,חלית,חליתם], באחד מהדברים הבאים כדי להשפיע על מהלך המחלה?"
            }
        },
        remove80
    ],
    activePre: [
        remove80
    ]
}

type questionnaireName = 'recovered' | 'activePre' | 'active';
export const allQuestionnaires: questionnaireName[] = ['recovered', 'activePre', 'active'];

export const applyModifications = (questionnaire: questionnaireName, questions: ChatQuestion[]) => {
    const modifications = allModifications[questionnaire as keyof typeof allModifications];
    
    modifications?.forEach(modification => {
        const index = questions.findIndex(q => q.id === modification.id);
        if(modification.action === 'modify')
            return Object.assign(questions[index], (modification as modAction).updates);
        else if(modification.action === 'remove')
            questions.splice(index, 1);
    });
    
    return questions;
}

/*
בשאלה הבאה חשוב שתצייני... בהמשך תישאל  גם על מועד התחלת התסמינים שאתה סובל מהם כעת.
בהמשך [g:תישאל,תישאלי,תישאלו]  גם על מועד התחלת התסמינים ש[g:אתה סובל,את סובלת,אתם סובלים] מהם כעת.

במקום "האם התסמינים מהם את סובלת היום קיימים...?", "התסמינים מהם אני סובל נמשכים:" ["כבר שנים רבות", "מאז שחוסנתי", "מאז שחליתי בקורונה"]
אם התשובה היא שנים רבות או מאז שחוסנתי - "האם אחרי שחלית בקורונה חל שינוי בתסמינים האלו?" ["לא", "כן, הם החמירו", "כן, הם השתפרו"]

122----"האם יש תרופה נוספת..." לשנות ל-"האם היו תרופות נוספות, תוספי מזון, צמחי מרפא, או משהו מיוחד באורח החיים שלך לפני שחלית בקורונה שאתה חושב שהשפיע על מהלך המחלה במקרה שלך?"

130----"מה מהדברים הבאים עזר לך להחלים טוב יותר", להוסיף אפשרות "העובדה שחוסנתי" + "אחר"
	"מה מהדברים הבאים השפיע לרעה" צריך להיות checkboxes. להוסיף גם "אחר"

"האם את נעזרת או נעזרת בעבר..." לשנות ל-"האם בעת שחלית נעזרת באחד מהדברים הבאים כדי להשפיע על מהלך המחלה?" בשביל חולים בעבר, חולים בהווה להשאיר כמו שזה
האם בעת ש[g:חלית נעזרת,חלית נעזרת,חליתם נעזרתם] באחד מהדברים הבאים כדי להשפיע על מהלך המחלה?
*/